import React, { useState } from 'react';
import AudioRecorderComponent from './AudioRecorder'; // This is the detailed recording UI component
import toast from 'react-hot-toast'; // Ensure you have this installed: `npm install react-hot-toast`
import { Note } from '../NotesContainer'; // Replace with the correct path to where Note is defined


type NoteManagerProps = {
  addNote: (val: Note) => void;
  removeNote: (id: string) => void;
};


const NoteManager = ({ addNote, removeNote }: NoteManagerProps) => {


  return (
    <div className="relative flex flex-col items-center">
      {/* Only renders AudioRecorderComponent */}
      <AudioRecorderComponent addNote={addNote} removeNote={removeNote} />
    </div>
  );
};

export default NoteManager;
