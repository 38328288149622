import React, { useState, useRef, useEffect } from 'react';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import toast from 'react-hot-toast';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { Note } from '../NotesContainer';

interface AudioRecorderProps {
  addNote: (val: Note) => void;
  removeNote: (id: string) => void;
}

const generateUniqueId = () => `note-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

const AudioRecorderComponent = ({ addNote, removeNote }: AudioRecorderProps) => {

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.error('Error:', err) // Handles errors like microphone not found
  );
  const [isExpanded, setIsExpanded] = useState(false); // To handle the expansion of the panel
  const [isReadyForSave, setIsReadyForSave] = useState(false); // To handle the expansion of the panel

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [time, setTime] = useState(0); // Timer state

  // Timer update
  useEffect(() => {
    let interval: any;
    if (recorderControls.isRecording && !recorderControls.isPaused) {
      interval = setInterval(() => setTime((prev) => prev + 1), 1000);
    } else if (recorderControls.isPaused || !recorderControls.isRecording) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [recorderControls.isRecording, recorderControls.isPaused]);
  
  // Format timer as mm:ss
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handlePauseResume = () => {
    if (recorderControls.isPaused) {
      recorderControls.togglePauseResume();
    } else {
      recorderControls.togglePauseResume();
    }
  };

  const handleStartRecording = () => {
    setIsExpanded(true);
    recorderControls.startRecording();
  };

  const handleSaveRecording = () => {
    setIsReadyForSave(true);
    recorderControls.stopRecording();
    setIsExpanded(false);
  };

  const resetRecorder = () => {
    setTime(0);
    setIsExpanded(false);
    setIsReadyForSave(false);
    recorderControls.stopRecording();
  };

  const handleUpload = async () => {
    if (recorderControls.recordingBlob) {
      const id = generateUniqueId();
      addNote({
          id, // Assign the unique ID to the uploading note
          created_at: new Date().toISOString(), // Ensure the timestamp is current
          title: "Uploading...",
          content: "Transcripting...",
        });
      const formData = new FormData();
      let fileExtension = '';

      // Handle different MIME types and assign appropriate file extensions
      switch (recorderControls.recordingBlob.type) {
        case 'audio/wav':
          fileExtension = '.wav';
          break;
        case 'audio/webm':
          fileExtension = '.webm';
          break;
        case 'audio/mp4': // Chrome and Firefox may generate mp4 blobs
          fileExtension = '.mp4'; // Save as m4a for consistency across browsers
          break;
        case 'audio/mpeg':
          fileExtension = '.mp3';
          break;
        default:
          fileExtension = '.wav'; // Fallback to WAV if type is unknown
      }
      let filename = 'recording' + fileExtension
      console.log(filename)
      formData.append('audio', recorderControls.recordingBlob, filename);
      try {
        const response = await fetch('/api/notes/upload', {
          method: 'POST',
          credentials: 'include',
          body: formData,
        });
        const data = await response.json();

        if (data.error) {
          toast.error(`Error: ${data.error}`);
        } else {
          removeNote(id)
          addNote({ ...data});
          toast.success('Note saved!');
        }
      } catch (error) {
        toast.error('Error uploading audio');
        console.error('Error uploading audio:', error);
      }
      return ;
    }
  };


  useEffect(() => {
    const uploadAndReset = async () => { // Make this function async
      if   (!recorderControls.recordingBlob) return;

      console.log("In effect", isReadyForSave);
      console.log(recorderControls.recordingBlob);
      console.log(recorderControls.recordingBlob.type);

      if (audioRef.current && recorderControls.recordingBlob) {
        const audioURL = URL.createObjectURL(recorderControls.recordingBlob);
        audioRef.current.src = audioURL;
        audioRef.current.load();  // Ensure that the audio is loaded before playing
        if(isReadyForSave)
         await handleUpload(); // Now this can be awaited
        resetRecorder();
      }
    };

    uploadAndReset(); // Call the async function
  }, [recorderControls.recordingBlob])

  return (
    <div className="fixed left-2 right-2 bottom-4 flex justify-center">
      <audio ref={audioRef} controls className="hidden"></audio>

      
      {recorderControls  && recorderControls.isRecording ? (
        <div 
          className="bg-accent flex justify-between items-center shadow-md transition-all h-min"
          style={{
            borderRadius: '30px',
            transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out',
          }}
        >
          {/* Trash Button - Discard Recording */}
          <button onClick={resetRecorder} className="bg-accent rounded-full flex justify-center items-center mx-4 h-10 w-10">
            <i className="fa-solid fa-trash text-red-600 cursor-pointer text-md"></i>
          </button>

          {/* Timer */}
          {recorderControls.mediaRecorder && (
            <div className='flex justify-between items-center text-white'>
              <span className="text-xs font-semibold text-white pr-2">{formatTime(time)}</span>
              <LiveAudioVisualizer
                mediaRecorder={recorderControls.mediaRecorder}
                barWidth={1}
                gap={1}
                width={"100%"}
                height={30}
                barColor={'#FFFFFF'}
                fftSize={1024}
                maxDecibels={-10}
                minDecibels={-80}
                smoothingTimeConstant={0.4}
              />
              <span className='text-xs font-semibold text-white pl-2'>3:00</span>
            </div>
          )}

          {/* Pause / Resume Button */}
          <button
            onClick={handlePauseResume}
            className="bg-accent rounded-full flex justify-center items-center mx-2 h-10 w-10" >
            {recorderControls && recorderControls.isPaused ? (
              <i className="fa-solid fa-play cursor-pointer text-white-500 text-md"></i>
            ) : (
              <i className="fa-solid fa-pause cursor-pointer text-white-500 text-xl"></i>
            )}
          </button>

          {/* Upload Button */}
          <button
            onClick={handleSaveRecording}
            className="rounded-full flex justify-center items-center bg-primary font-semibold py-[11px] px-[12px] hover:bg-opacity-90 transition-all mr-3 my-3"
          >
            <i className="fa-solid fa-arrow-up cursor-pointer text-md -ml-[1px]"></i>
          </button>
        </div>
      ) : (
        <div className="">
          {/* Initial record button */}
          <button
            onClick={handleStartRecording}
            className="bg-accent w-16 h-16 rounded-full flex justify-center items-center shadow-md text-white font-semibold"
            style={{
              width: isExpanded ? '90%' : '60px',
              height: isExpanded ? '60px' : '60px',
              borderRadius: isExpanded ? '30px' : '30px',
              transition: 'width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out',
            }}
          >
            <div className="p-[14px]">
              <i className="fa-solid fa-microphone text-white-500 text-xl"></i>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default AudioRecorderComponent;
