import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "./ModalProvider";
import { BottomSheet } from "react-spring-bottom-sheet";
import 'react-spring-bottom-sheet/dist/style.css'

const Modal = () => {
  let { modalContent, handleModal, modal, modalButton } = React.useContext(ModalContext);

  const closeModal = () => {
    handleModal();
  }

  const handleInsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }

  if (modal) {
    const content = typeof modalContent === 'string' ? (
      <p>{modalContent}</p>
    ) : (
      modalContent
    );
    return ReactDOM.createPortal(
      <>
        <div
          className="fixed top-0 left-0 h-screen w-full md:flex items-center justify-center z-20 hidden"
          style={{ background: "rgba(0,0,0,0.8)" }}
          onClick={closeModal}
        >
          <div onClick={handleInsideClick} className="bg-white relative p-5 shadow-lg rounded flex flex-col items-start text-lg text-gray-800">
            <button
              className="absolute top-0 right-0 -mt-12 rounded-full bg-white text-red-700 w-8 h-8 flex justify-center items-center -pt-1"
              onClick={closeModal}
            >
              <div className="mb-[2px]">

              &times;
              </div>
            </button>
            {content}
          </div>
        </div>
        <BottomSheet className="md:hidden" open={modal} onDismiss={closeModal}>
          <div className="pb-4 pt-1 px-4 ">
            {content}
            {/* <div className="flex gap-2 mt-4">
              <button onClick={closeModal} className="secondary flex-1 font-bold text-sm border-2 bg-white border-[color:var(--color-accent)] text-[color:var(--color-accent)]">
                Close
              </button>
              {modalButton}
            </div> */}
          </div>
        </BottomSheet>
      </>,
      document.querySelector("#modal-root")!
    );
  } else return null;
};

export default Modal;