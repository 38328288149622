import React, { useCallback, useEffect, useState } from 'react';
import { Note } from '.';

interface NoteDetailsProps {
  note: Note;
  updateNote: (note: Note) => void;
  deleteNote: (id: string) => void;
  handleClose: () => void;
}

const NoteDetails: React.FC<NoteDetailsProps> = ({ note, updateNote, deleteNote, handleClose }) => {
  const [similar, setSimilar] = useState<Note[]>([]);
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState(note.content);

  useEffect(() => {
    fetchSimilarNotes();
  }, []);

  const fetchSimilarNotes = useCallback(async () => {
    console.log('Fetching similar notes...');
    try {
      const response = await fetch(`/api/notes/similar_notes/${note.id}`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setSimilar(data.notes);
      } else {
        console.error('Failed to fetch similar notes');
      }
    } catch (error) {
      console.error('Error fetching similar notes:', error);
    }
  }, []);

  const dateFormatter = (date: string) => {
    // 10:00 pm, 27th Aug
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.getFullYear();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const ordinal = (day % 10 === 1 && day !== 11) ? 'st'
      : (day % 10 === 2 && day !== 12) ? 'nd'
      : (day % 10 === 3 && day !== 13) ? 'rd'
      : 'th';
    return `${formattedHours}:${formattedMinutes} ${ampm}, ${day}${ordinal} ${month}`;
  }

  const handleEdit = () => {
    setEdit(!edit);
  }

  const handleUpdate = async () => {
    try {
      const response = await fetch(`/api/notes/update_content/${note.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ content }),
      });
      if (response.ok) {
        setEdit(false);
        updateNote({
          ...note,
          content,
        });
      } else {
        console.error('Failed to update note');
      }
    } catch (error) {
      console.error('Error updating note:', error);
    }
  }

  const handleDelete = async () => {
    const token = localStorage.getItem('user');
    if (!token) {
      return;
    }
    
      try {
        const response = await fetch(`/api/notes/delete/${note.id}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        if (response.ok) {
          deleteNote(note.id);
          handleClose()
        } else {
          console.error('Failed to delete note');
        }
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    
  }
  
  return (
    <div className=''>
      <div className='font-bold text-lg'>{note.title}
      <button
          onClick={handleDelete}
          className="absolute right-2 font-bold text-[14px] bg-transparent text-secondary py-1 px-3 focus:outline-none">
          <i className="fa-solid fa-trash text-stop"></i>
        </button>
      </div>
      <div className='bg-accent/50 w-full h-[1px] mt-1 mb-2' />
      <div className='flex justify-between items-center'>
        <div className='flex items-center gap-2 mt-1 text-gray-400'>
          <i className="fa-regular fa-calendar text-[14px] -mt-[1px]"></i>
          <div className='text-xs'>{dateFormatter(note.created_at)}</div>
        </div>
      </div>
      {edit ? (
        <textarea
          className='mt-4 w-full h-40 focus:outline-accent'
          value={content}
          onChange={(e) => setContent(e.target.value)}
          autoFocus />
      ) : (
        <div
          className='mt-4'
        >
          {content}
        </div>
      )}
      <div className='flex pt-2 gap-1'>
        {/* <button
          onClick={() => {}}
          className="font-bold secondary bg-white text-secondary py-1 px-3"
        >
          <i className="fa-solid fa-trash"></i> 
        </button> */}
        {edit && (
          <button
            onClick={handleEdit}
            className="font-bold secondary bg-white text-secondary py-1 px-3"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
        )}
        {edit ? (
          <button
            onClick={handleUpdate}
            className="bg-accent flex-1 font-bold text-sm "
          >
            <i className="fa-solid fa-check mr-2"></i>
            Save
          </button>
        ) : (
          <button
            onClick={handleEdit}
            className="font-bold text-sm bg-transparent text-text">
            <i className="fa-regular fa-solid fa-pen-to-square"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default NoteDetails;