import React, { useCallback, useEffect, useState } from 'react';
import useAuth from '../../utils/Auth';
import NotesContainer, { Note } from './NotesContainer';
import NoteManager from './NoteManager';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../utils/Navbar';

const NotesScreen: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [notes, setNotes] = useState<Note[]>([]);

  useEffect(() => {
    if (user) {
      fetchNotes();
    } else {
      navigate('/login', { replace: true });
    }
  }, [user]);

  const fetchNotes = useCallback(async () => {
    console.log('Fetching notes...');
    try {
      const response = await fetch('/api/notes/user', {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setNotes(data.notes);
      } else {
        console.error('Failed to fetch notes');
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  }, []);

  const addNote = (val: Note) => {
    if (val) { // Check if val is truthy (not undefined or null)
      setNotes([val, ...notes]); // Add the new note to the top of the existing notes array
    }
  };

  const removeNote = (id: string) => {
    const newNotes = notes.filter(note => note.id !== id); // Change == to !== for correct filtering
    setNotes(newNotes); // Call setNotes to update the state
  }

  return (
    <div className="h-screen overflow-y-scroll bg-text/[0.07]">
      <Navbar />
      <div className="w-full">
        <NotesContainer notesData={notes} />
        <div className='fixed left-0 right-0 bottom-0'>
          <NoteManager addNote={addNote} removeNote={removeNote} />
        </div>
      </div>
    </div>
  );
};

export default NotesScreen;